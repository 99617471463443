<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { computed, inject, onMounted, ref } from 'vue';

const props = defineProps({
	path: {
		type: String,
		required: true,
	},
});

// Filling nav_links ref for NavDropDown - Automatically (Provider, injector pattern)

const nav_links = inject('nav_links', null);

const is_under_nav_dropdown = inject('is_under_nav_dropdown', null);

const is_under_no_root_nav_dropdown = computed(() => {
	if (is_under_nav_dropdown === null) {
		return false;
	}

	return !is_under_nav_dropdown.is_root_menu.value;
});

const page = usePage();

const is_active = computed(() => page.url.startsWith(props.path));

onMounted(() => {
	if (nav_links) {
		nav_links.value.push(props.path);
	}
});
</script>

<template>
	<li
		class="menu nav-item"
		:class="{
			'rounded-lg py-1 pl-3 pr-2.5': !is_under_nav_dropdown,
		}"
	>
		<a
			class="nav-link group cursor-pointer transition-colors duration-500 ease-in-out hover:text-purple"
			:class="{ active: is_active }"
			@click.prevent="router.visit(path)"
		>
			<div class="flex items-center">
				<span
					class="relative ml-5 mr-1 h-3 w-3.5"
					v-if="is_under_no_root_nav_dropdown"
				>
					<i
						class="ri-arrow-right-line absolute right-0 top-1/2 z-10 -translate-y-1/2 scale-95"
					></i>
					<i
						class="arrow-icon-line absolute left-0 top-1/2 z-0 h-0 w-2.5 -translate-y-1/2 border-t"
					></i>
				</span>
				<slot name="default" :active="is_active" />
			</div>
		</a>
	</li>
</template>
