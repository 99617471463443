<template>
	<!-- Content container -->
	<main class="p-7">
		<slot />
	</main>
	<!-- Footer -->
	<footer class="z-10 pb-7 text-center text-sm text-[#9B6998]">
		&copy; 2022 - {{ new Date().getFullYear() }} FraudCentral LLC, Mission
		Control Portal
	</footer>
</template>
