<script setup>
import { ref, computed } from 'vue';
import NavLink from '@/Components/NavLink.vue';
import { Link, usePage } from '@inertiajs/vue3';
import Dropdown from '@/Components/Dropdown.vue';
import NavDropdown from '@/Components/NavDropdown.vue';
import RobodnoIcon from '@/Components/Icons/SideMenu/RobodnoIcon.vue';
import RoboToolIcon from '@/Components/Icons/SideMenu/RoboToolIcon.vue';
import FraudCentralIoLogo from '@/Components/Logo/FraudCentralIoLogo.vue';
import AnalyticsIcon from '@/Components/Icons/SideMenu/AnalyticsIcon.vue';
import HelpCentreIcon from '@/Components/Icons/SideMenu/HelpCentreIcon.vue';
import CallsEventsBoldIcon from '@/Components/Icons/CallsEventsBoldIcon.vue';
import RobocheckKynIcon from '@/Components/Icons/SideMenu/RobocheckKynIcon.vue';
import RoboCheckKycIcon from '@/Components/Icons/SideMenu/RoboCheckKycIcon.vue';
import DashboardBoldIcon from '@/Components/Icons/SideMenu/DashboardBoldIcon.vue';
import YourHoneypotsIcon from '@/Components/Icons/SideMenu/YourHoneypotsIcon.vue';
import CampaignCheckIcon from '@/Components/Icons/SideMenu/CampaignCheckIcon.vue';
import HoneypotsAlertsIcon from '@/Components/Icons/SideMenu/HoneypotsAlertsIcon.vue';
import RobocheckAddonsIcon from '@/Components/Icons/SideMenu/RobocheckAddonsIcon.vue';
import AccountSettingsIcon from '@/Components/Icons/SideMenu/AccountSettingsIcon.vue';
import NumberManagementIcon from '@/Components/Icons/SideMenu/NumberManagementIcon.vue';
import HoneypotsStirShakenIcon from '@/Components/Icons/SideMenu/HoneypotsStirShakenIcon.vue';
import FraudCentralIoHorizontalLogo from '@/Components/Logo/FraudCentralIoHorizontalLogo.vue';
import CertificateOverviewIcon from '@/Components/Icons/SideMenu/CertificateOverviewIcon.vue';
import HoneypotsNonAttestedIcon from '@/Components/Icons/SideMenu/HoneypotsNonAttestedIcon.vue';
import CertificateReportCardsIcon from '@/Components/Icons/SideMenu/CertificateReportCardsIcon.vue';

// Sidebar Toggled
// - If >= lg => toggle will fold (collapse) the sidebar
// - if < lg => toggle will open the sidebar
const sidebar_toggled = ref(false);
const page = usePage();

function isFeatureEnabled(feature) {
	return page?.props?.auth?.enabled_features?.[feature];
}

// List of all addons
const addons_list = ['campaign_check'];

// Check if any addons are enabled
const has_addons = computed(() =>
	addons_list.some((addon) => isFeatureEnabled(addon))
);
</script>

<template>
	<!-- Start Layout -->
	<div
		class="vertical h-dvh bg-black"
		:class="{ 'toggle-sidebar': sidebar_toggled }"
	>
		<!-- Start Menu Sidebar Overlay -->
		<div
			class="fixed inset-0 z-40 bg-black/90 lg:hidden"
			:class="{ hidden: !sidebar_toggled }"
			@click="sidebar_toggled = false"
		></div>
		<!-- End Menu Sidebar Olverlay -->
		<!-- Start Main Content -->
		<div class="main-container mx-auto h-full">
			<!-- Start Sidebar -->
			<nav
				class="sidebar fixed z-50 w-[300px] flex-none overflow-hidden bg-darklight transition-all duration-300"
			>
				<div class="h-full">
					<!-- Start Logo -->
					<div class="p-4 pl-6">
						<Link class="main-logo w-full" href="/">
							<div class="logo mx-auto h-7">
								<FraudCentralIoHorizontalLogo />
							</div>

							<!-- Smaller version (without text) -->
							<div class="logo-icon mx-auto hidden h-7">
								<FraudCentralIoLogo />
							</div>
						</Link>
						<!-- End Logo -->
					</div>
					<!-- Menu -->
					<div
						class="menu-container mt-6 h-[calc(100vh-60px)] space-y-16 overflow-y-auto overflow-x-hidden px-3 pb-4"
					>
						<ul class="relative flex flex-col gap-3">
							<!-- Section:Robocheck KYN -->
							<NavDropdown title="RoboCheck&reg; KYN">
								<template #icon>
									<RobocheckKynIcon />
								</template>
								<NavLink path="/dashboard">
									<DashboardBoldIcon class="size-5" />
									<span class="ml-2 font-bold">Dashboard</span>
								</NavLink>
								<!-- Your Honeypots -->
								<NavDropdown title="Your Honeypots">
									<template #icon>
										<YourHoneypotsIcon />
									</template>
									<NavLink path="/honeypots">View all Honeypots</NavLink>
									<NavLink path="/tracebacks">Traceback Honeypots</NavLink>
									<!-- TODO: -->
									<NavLink path="/somos">SOMOS DNO Honeypots</NavLink>
								</NavDropdown>
								<!-- Analytics & Reports -->
								<NavDropdown title="Analytics & Reports">
									<template #icon>
										<AnalyticsIcon />
									</template>
									<NavLink path="/traffic-analysis">Traffic Analysis</NavLink>
								</NavDropdown>
								<!-- Honeypot Alerts -->
								<NavLink class="font-bold" path="/alerts">
									<HoneypotsAlertsIcon />&nbsp; Honeypot Alerts
								</NavLink>
							</NavDropdown>
							<!-- Section:Robocheck KYC -->
							<NavDropdown
								title="RoboCheck&reg; KYC"
								v-if="isFeatureEnabled('kyc')"
							>
								<template #icon>
									<RoboCheckKycIcon />
								</template>
								<!-- Certificate Reports -->
								<NavLink class="font-bold" path="/kyc/certificate-report-card">
									<CertificateReportCardsIcon />
									<span class="ml-2">Certificate Report Cards</span>
								</NavLink>
								<NavLink class="font-bold" path="/kyc/certificate-overview">
									<CertificateOverviewIcon />
									<span class="ml-2">Certificate Overview</span>
								</NavLink>
								<!-- Honeypots STIR/SHAKEN -->
								<NavDropdown title="Honeypots STIR/SHAKEN">
									<template #icon>
										<HoneypotsStirShakenIcon class="size-5" />
									</template>
									<NavLink path="/kyc/honeypots">View Honeypots</NavLink>
									<NavLink path="/kyc/global-traffic-analysis">
										Traffic Analysis
									</NavLink>
								</NavDropdown>
								<!-- Honeypots Non-Attested -->
								<NavDropdown title="Honeypots Non-Attested">
									<template #icon>
										<HoneypotsNonAttestedIcon class="size-5" />
									</template>
									<NavLink path="/compliance/honeypots">
										View Honeypots
									</NavLink>
									<NavLink path="/compliance/traffic-analysis">
										Traffic Analysis
									</NavLink>
								</NavDropdown>
							</NavDropdown>
							<!-- Section:Robocheck Add-ons -->
							<NavDropdown title="RoboCheck&reg; Add-ons" v-if="has_addons">
								<template #icon>
									<RobocheckAddonsIcon />
								</template>
								<!-- -- Campaign check tool -->
								<NavLink
									path="/tools/campaign-check"
									v-if="isFeatureEnabled('campaign_check')"
								>
									<CampaignCheckIcon />
									<span class="ml-2 font-bold">Campaign Check</span>
								</NavLink>
							</NavDropdown>
							<!-- Section:IPES OCN Management -->
							<NavDropdown
								v-if="isFeatureEnabled('ipes_management')"
								title="Number Management"
							>
								<template #icon>
									<NumberManagementIcon />
								</template>
								<!-- -- IPES:Dashboard -->
								<NavLink path="/ipes/dashboard">
									<DashboardBoldIcon class="size-5" />
									<span class="ml-2 font-bold">Dashboard</span>
								</NavLink>
								<!-- -- IPES:RoboCheck® Call Records -->
								<NavDropdown title="RoboCheck&reg; Call Records">
									<template #icon>
										<i class="nav-icon ri-file-lock-line text-xl"></i>
									</template>
									<NavLink path="/ipes/robocheck/attested"
										>With Attestation</NavLink
									>
									<NavLink path="/ipes/robocheck/non-attested"
										>Non-Attested</NavLink
									>
									<NavLink path="/ipes/robocheck/records">All Records</NavLink>
								</NavDropdown>
								<!-- -- IPES:RoboDNO® Call Records -->
								<NavDropdown title="RoboDNO&reg; Call Records">
									<template #icon>
										<RobodnoIcon class="size-5" />
									</template>
									<NavLink path="/ipes/robodno/attested">
										With Attestation
									</NavLink>
									<NavLink path="/ipes/robodno/non-attested">
										Non-Attested
									</NavLink>
									<NavLink path="/ipes/robodno/records">All Records</NavLink>
								</NavDropdown>
								<!-- -- Robocheck Call-Back Events -->
								<NavLink path="/ipes/robocheck/callback">
									<CallsEventsBoldIcon class="scale-75" />
									<span class="ml-2 font-bold">Call-Back Events</span>
								</NavLink>
								<!-- -- Search & Statistics -->
								<NavDropdown title="Search & Statistics">
									<template #icon>
										<i class="nav-icon ri-line-chart-line text-xl"></i>
									</template>
									<NavLink path="/ipes/quick-number-search">
										Quick Number Search
									</NavLink>
									<NavLink path="/ipes/certificate-search">
										Certificate Search
									</NavLink>
									<NavLink path="/ipes/ocn-statistics">
										OCN Statistics
									</NavLink>
								</NavDropdown>
							</NavDropdown>
							<!-- Section:RoboTools -->
							<NavDropdown title="RoboTools&reg;">
								<template #icon>
									<RoboToolIcon />
								</template>
								<!-- -- Ip Search tool -->
								<NavLink path="/tools/ip-search">
									<i class="nav-icon ri-search-fill text-lg"></i>
									<span class="ml-2 font-bold">Media IP Search</span>
								</NavLink>
								<!-- -- RMD Search tool -->
								<NavLink path="/tools/rmd-search">
									<i class="nav-icon ri-menu-search-fill text-lg"></i>
									<span class="ml-2 font-bold">RMD Search</span>
								</NavLink>
							</NavDropdown>
							<!-- Section:Help Centre -->
							<NavDropdown title="Help Centre">
								<template #icon>
									<HelpCentreIcon />
								</template>
								<!-- -- F.A.Q. & Definitions -->
								<NavLink path="/faq">
									<span class="ml-2 font-bold">F.A.Q. & Definitions</span>
								</NavLink>
								<!-- -- Data Matching Policyl -->
								<NavLink path="/policy">
									<span class="ml-2 font-bold">Data Matching Policy</span>
								</NavLink>
							</NavDropdown>
							<NavLink path="/account">
								<AccountSettingsIcon />
								<span class="ml-2 font-bold">Account Settings</span>
							</NavLink>
						</ul>
					</div>
				</div>
			</nav>
			<!-- End sidebar -->
			<!-- Start Content Area -->
			<div class="main-content flex h-full flex-col">
				<!-- Start Topbar -->
				<div
					class="relative flex h-[55px] shrink-0 items-center justify-between gap-4 border-b border-darkborder bg-darklight px-4 lg:z-40"
				>
					<div class="flex flex-1 items-center gap-2 sm:gap-4">
						<button
							class="text-2xl text-white/80"
							type="button"
							@click="sidebar_toggled = !sidebar_toggled"
						>
							<i class="ri-menu-3-fill" v-if="sidebar_toggled"></i>
							<i class="ri-menu-2-fill" v-else></i>
						</button>
					</div>
					<div class="flex items-center gap-4">
						<Dropdown />
					</div>
				</div>
				<!-- End Topbar -->

				<!-- Start Content -->
				<div
					class="relative grow space-y-4 overflow-y-auto overflow-x-hidden border-l border-darkborder p-4"
					scroll-region
				>
					<!-- Start All Card -->
					<div class="flex min-h-96 flex-col gap-4">
						<slot />
					</div>

					<!-- Start Footer -->
					<footer class="pl-2 text-center text-xs text-darkmuted sm:text-sm">
						&copy; 2022 - {{ new Date().getFullYear() }} FraudCentral LLC,
						Mission Control Portal
					</footer>
					<!-- End Footer -->
				</div>
				<!-- End Content -->
			</div>
			<!-- End Content Area -->
		</div>
	</div>
	<!-- End Layout -->
</template>
